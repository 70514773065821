import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";

const Map = lazy(() => import("../pages/map/Index"));
const Faq = lazy(() => import("../pages/faq/Index"));

export const Routes = () => {
  return (
    <Suspense
      fallback={
        <div
          style={{ margin: "48px", display: "flex", justifyContent: "center" }}
        >
          <CircularProgress />
        </div>
      }
    >
      <Switch>
        <Route path="/faq" exact component={Faq} />
        <Route path="/map" component={Map} />
        <Route exact path="/" render={() => <Redirect to="/map" />} />
      </Switch>
    </Suspense>
  );
};
